import classNames from 'classnames'

import styles from './BecomeSpeaker.module.scss'

const BecomeSpeaker = () => {
  return (
    <div className={styles.wrapper} id='become-a-speaker'>
      <p className={styles.wrapper__title}>Become a Speaker</p>
      <p className={styles.wrapper__subtitle}>Deadline: February 15, 2025</p>
      <div className={classNames('', styles.wrapper__sizer)}>
        <a href='https://forms.gle/EZ98FUaAcns9EJ9x5' target='_blanck'>
          <button>Submit your talk</button>
        </a>
      </div>
    </div>
  )
}

export default BecomeSpeaker
